/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CancelPopupType,
  CustomUploadFile, Document, DocumentDeletedType,
  DocumentUploaded,
  HubCompany, SearchList, SetDocuments, SetDownloadURL, SetSearchListWithIndex,
  SortType, UploadDocumentStatus, SetSelectToggle,
  AddRemoveDocInSearchList,
  Folder,
  DocumentHubHeader,
  DocHubCurrentViewType,
  DocHubSortType,
  AllUploadDocument,
  FileWithId,
  RenameData,
  SelectedDocument,
  DocHubEntity,
  CreateFolderPopup,
  CollabPopupViewType,
  CollabPopupResponse,
  CollabPopupDataResponse,
  UpdatedFolder,
  FolderDeletedType,
  CollaboratorsPopup,
  SearchListPeople,
  SelectAllPeopleRequest,
  SelectPersonRequest,
  PeopleSearchSelectionToggle,
  SetPeopleSearchListWithIndex,
  UpdateSearchListPeople,
  AddRemoveDocInPeopleSearchList,
  UpdateCollaboratorsAccess,
} from 'Types/DocumentHubTypes';
import { DocumentHubState } from 'Types/GlobalTypes';
import { Executive } from 'Types/PersonTypes';
import {
  CollabPopupDataRequest,
  CreateFolderRequest,
  DocumentDeleteRequest, DocumentPreviewRequest, DocumentsRequest,
  FolderDeleteRequest,
  FoldersRequest,
  GetDocumentDownloadRequest, MoveCopyRequest, RenameDocumentRequest, UpdateCollaboratorsRequest, UpdateFolderRequest, UploadRequest,
  ValidateFileNameRequest,
} from 'Types/RequestBodies';
import { getFileSize, isDocument, isFolder } from 'Utils/UtilityFunctions';
import _ from 'lodash';

export const initialState: DocumentHubState = {
  itemCount: 0,
  userDocumentCount: 0,
  isSelectAll: false,
  isSearchSelectAll: false,
  selectedDocuments: [],
  unSelectedDocuments: [],
  searchList: [],
  uploadedByMe: false,
  selectedDocumentCount: 0,
  unselectedDocumentCount: 0,
  searchTerm: '',
  entities: [],
  entitiesEnded: false,
  entitiesLoaded: false,
  entitiesLoading: false,
  docHubSort: 'lastModified:desc',
  sort: 'des',
  sortType: 'date',
  companyId: 0,
  companyName: '',
  companyLogo: '',
  chatgptAllowed: false,
  isAdmin: false,
  uploaded: false,
  uploading: false,
  drawerOpen: false,
  deleteing: false,
  deleteLoading: false,
  downloading: false,
  downloadUrl: '',
  drawerState: 'file',
  uploadedDocuments: [],
  uploadDocuments: [],
  nextUploadDocuments: [],
  failedDocuments: [],
  successDocuments: [],
  allUploadDocuments: [],
  documentUploadCount: 0,
  batchSize: 0,
  batchUploading: false,
  uploadLink: '',
  cancelPopup: {} as CancelPopupType,
  uploadCancelled: false,
  downloadLoadingIds: [],
  fileDownloadURL: '',
  fileViewUrl: '',
  documentCount: 0,
  folderCount: 0,
  searchCountDelete: 0,
  sortFlurryEvent: '',
  fileTypeFlurryEvent: [],
  flurryFlag: true,
  previewDrawerOpen: false,
  uploadDocumentStatus: false,
  uploadingFileError: false,
  validateLoading: false,
  showSelected: false,
  sapSelectedDocOffset: 0,
  documentHubHeader: {
    type: 'folder', count: 0, name: '', logo: '',
  },
  currentView: 'folder',
  currentFolder: null,
  selectedFolderId: 0,
  deletePopupVisible: false,
  deletePopupType: 'folder',
  deleteId: -1,
  renameData: {
    fileName: '',
    id: 0,
    extension: '',
  },
  renameId: -1,
  error: false,
  createFolderPopup: {
    visible: false,
    gilroyToggle: true,
    folderName: '',
    creating: false,
    error: '',
  },
  foldersPopup: {
    folders: [],
    loaded: false,
    loading: false,
    ended: false,
    searchTerm: '',
    visible: false,
    type: 'copy',
    selectedFolder: null,
    sortType: 'lastModified:desc',
    foldersCount: 0,
    filesCount: 0,
    selectedDocumentIds: [],
    moveCopyLoading: false,
  },
  collaboratorsPopup: {
    updating: false,
    peopleSearchTerm: '',
    collaboratorsSearchTerm: '',
    people: [],
    collaborators: [],
    peopleLoading: false,
    collaboratorsLoading: false,
    peopleLoaded: false,
    collaboratorsLoaded: false,
    peopleEnded: false,
    collaboratorsEnded: false,
    currentView: 'people',
    peopleCount: 0,
    collaboratorsCount: 0,
    visible: false,
    folderId: 0,
    isPeopleSelectAll: false,
    isCollaboratorsSelectAll: false,
    isPeopleSearchSelectAll: false,
    isCollaboratorsSearchSelectAll: false,
    selectedPeople: [],
    unSelectedPeople: [],
    selectedCollaborators: [],
    unSelectedCollaborators: [],
    peopleSearchList: [],
    collaboratorsSearchList: [],
  },
  fromGlobalSearch: false,
  sapRadio: 'document',
};

const documentHubSlice = createSlice({
  name: 'documentHub',
  initialState,
  reducers: {
    getDocuments(state, action: PayloadAction<DocumentsRequest>) {
      state.entitiesLoading = true;
      state.entitiesEnded = false;
      if (action.payload.offset === 0) {
        state.entities = [];
        state.entitiesLoaded = false;
      }
      state.currentView = 'document';
    },
    getFolders(state, action: PayloadAction<FoldersRequest>) {
      const { from } = action.payload;
      if (from === 'foldersPopup') {
        state.foldersPopup.loading = true;
        state.foldersPopup.ended = false;
        if (action.payload.offset === 0) {
          state.foldersPopup.folders = [];
          state.foldersPopup.loaded = false;
        }
      } else {
        state.entitiesLoading = true;
        state.entitiesEnded = false;
        if (action.payload.offset === 0) {
          state.entities = [];
          state.entitiesLoaded = false;
        }
        if(action.payload.searchTerm?.length){
          state.currentView = 'both';
        }
        else{
          state.currentView = 'folder';
        }
      }
    },
    getCollabPopupData(state, action:PayloadAction<CollabPopupDataRequest>) {
      const { type } = action.payload;
      if (type === 'collaborators') {
        state.collaboratorsPopup.collaboratorsLoading = true;
        state.collaboratorsPopup.collaboratorsEnded = false;
        if (action.payload.offset === 0) {
          state.collaboratorsPopup.collaborators = [];
          state.collaboratorsPopup.collaboratorsLoaded = false;
        }
      } else {
        state.collaboratorsPopup.peopleLoading = true;
        state.collaboratorsPopup.peopleEnded = false;
        if (action.payload.offset === 0) {
          state.collaboratorsPopup.people = [];
          state.collaboratorsPopup.peopleLoaded = false;
        }
      }
    },
    updateCollaborators(state, action: PayloadAction<UpdateCollaboratorsRequest>){
      state.collaboratorsPopup.updating = true;
    },
    setCollabPopupData(state, action:PayloadAction<CollabPopupDataResponse>) {
      const {
        ListOfUsers, type, totalCollaborators, totalUsers,
      } = action.payload;
      if (totalCollaborators > -1) {
        state.collaboratorsPopup.collaboratorsCount = totalCollaborators;
      }
      if (totalUsers > -1) {
        state.collaboratorsPopup.peopleCount = totalUsers;
      }
      if (type === 'collaborators') {
        state.collaboratorsPopup.collaborators = state.collaboratorsPopup.collaborators
          .concat(ListOfUsers);
        state.collaboratorsPopup.collaboratorsLoading = false;
        state.collaboratorsPopup.collaboratorsLoaded = true;
        if (!ListOfUsers.length) {
          state.collaboratorsPopup.collaboratorsEnded = true;
        }
      } else {
        state.collaboratorsPopup.people = state.collaboratorsPopup.people.concat(ListOfUsers);
        state.collaboratorsPopup.peopleLoading = false;
        state.collaboratorsPopup.peopleLoaded = true;
        if (!ListOfUsers.length) {
          state.collaboratorsPopup.peopleEnded = true;
        }
      }
    },
    createFolder(state, action: PayloadAction<CreateFolderRequest>) {
      state.createFolderPopup.creating = true;
    },
    setCreatedFolder(state, action: PayloadAction<Folder>) {
      const folder = action.payload;
      folder.deleteAllowed = (folder.isAdmin === 1
        || folder.isDelegateAdmin === 1
        || folder.isOwner === 1) && folder.isSharedFolder === 0;
      state.entities = [
        folder,
        ...state.entities,
      ];
      state.foldersPopup.folders = [
        folder,
        ...state.foldersPopup.folders,
      ];
      state.documentHubHeader.count += 1;
    },
    setDocuments(state, action: PayloadAction<SetDocuments>) {
      const { payload } = action.payload;
      state.uploadedDocuments = state.uploadedDocuments.map((uploadedDoc) => ({
        ...uploadedDoc,
        type: 'document',
        deleteAllowed: state.currentFolder?.isSharedFolder
          ? uploadedDoc.deleteAllowed : state.currentFolder?.editAccess === 1,
      }));
      state.uploadedDocuments = state.uploadedDocuments.filter(
        (uploadedDoc) => !payload.some((doc) => uploadedDoc.id === doc.id),
      );
      let newDocs = payload.map((doc) => ({
        ...doc,
        type: 'document',
        deleteAllowed: state.currentFolder?.isSharedFolder
          ? doc.deleteAllowed : state.currentFolder?.editAccess === 1,
      }));
      newDocs = newDocs?.filter((doc) => !state.entities.some((d) => d.id === doc.id));
      state.entities = state.entities.concat(newDocs);
      state.sapSelectedDocOffset += payload.length;
      state.entitiesLoading = false;
      if (!payload.length) {
        state.entitiesEnded = true;
      }
      state.entitiesLoaded = true;
    },
    setFolders(state, action: PayloadAction<DocHubEntity[]>) {
      state.entities = state.entities.concat(action.payload.map((entity) => {
        if(entity.type === 'folder'){
          return {
            ...entity,
          deleteAllowed: ((entity as Folder).isAdmin === 1
            || (entity as Folder).isDelegateAdmin === 1 || (entity as Folder).isOwner === 1)
           && (entity as Folder).isSharedFolder === 0,
          }
        }
        else{
          return entity
        }
      }));
      state.entitiesLoading = false;
      if (!action.payload.length) {
        state.entitiesEnded = true;
      }
      state.entitiesLoaded = true;
    },
    setFoldersPopupFolders(state, action: PayloadAction<Folder[]>) {
      state.foldersPopup.folders = state.foldersPopup.folders
        .concat(action.payload.map((folder) => (
          {
            ...folder,
            deleteAllowed: (folder.isAdmin === 1
               || folder.isDelegateAdmin === 1 || folder.isOwner === 1)
               && folder.isSharedFolder === 0,
          })));
      state.foldersPopup.loading = false;
      if (!action.payload.length) {
        state.entitiesEnded = true;
      }
      state.entitiesLoaded = true;
    },
    setFoldersPopupFoldersCount(state, action: PayloadAction<number>) {
      state.foldersPopup.foldersCount = action.payload;
    },
    setFromGlobalSearch(state, action: PayloadAction<boolean>) {
      state.fromGlobalSearch = action.payload;
    },
    setFoldersPopupFilesCount(state, action: PayloadAction<number>) {
      state.foldersPopup.filesCount = action.payload;
    },
    setSapRadio(state, action: PayloadAction<'document' | 'custom'>) {
      state.sapRadio = action.payload;
    },
    setHubCompany(state, action: PayloadAction<HubCompany>) {
      const {
        companyLogo, companyName, companyId, chatgptAllowed, documentCount,
        isAdmin, userDocumentCount, searchTerm, itemCount
      } = action.payload;
      state.companyId = companyId;
      state.companyLogo = companyLogo;
      state.documentHubHeader.logo = companyLogo;
      state.companyName = companyName;
      state.documentHubHeader.name = companyName;
      state.chatgptAllowed = chatgptAllowed ?? true;
      state.documentCount = documentCount;
      state.isAdmin = isAdmin ?? true;
      state.userDocumentCount = userDocumentCount;
      state.itemCount = itemCount;

      const inSearchList = state.searchList?.some((item) => {
        if (item.searchTerm === searchTerm) {
          return true;
        }
        return false;
      });
    },
    setDocumentHubHeader(state, action: PayloadAction<DocumentHubHeader>) {
      state.documentHubHeader = action.payload;
    },
    setFoldersPopupSelectedFolder(state, action: PayloadAction<Folder | null>) {
      state.foldersPopup.selectedFolder = action.payload;
      if (action.payload === null || !action.payload) {
          state.drawerOpen = false;
        }
    },
    resetDocumentHub(state) {
      state.documentHubHeader = {
        type: 'folder', count: 0, name: '', logo: '',
      };
      state.currentView = 'folder';
      state.currentFolder = null;
      state.allUploadDocuments = [];
      state.selectedFolderId = 0;
      state.uploadedDocuments = [];
      state.docHubSort = 'lastModified:desc';
      state.entities = [];
      state.entitiesEnded = false;
      state.entitiesEnded = false;
      state.searchTerm = '';
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.uploadedByMe = false;
      state.selectedDocumentCount = 0;
      state.unselectedDocumentCount = 0;
    },
    setCurrentView(state, action: PayloadAction<DocHubCurrentViewType>) {
      if(action.payload !== state.currentView){
        state.searchTerm = '';
      }
      state.currentView = action.payload;
    },
    setDeletePopupVisible(state, action: PayloadAction<boolean>) {
      state.deletePopupVisible = action.payload;
    },
    setDeletePopupType(state, action: PayloadAction<string>) {
      state.deletePopupType = action.payload as 'folder' | 'document';
    },
    setDeleteId(state, action: PayloadAction<number>) {
      state.deleteId = action.payload;
    },
    setCurrentFolder(state, action: PayloadAction<Folder | null>) {
      state.currentFolder = action.payload;
      state.currentView = 'document';
      state.entities = [];
      state.entitiesEnded = false;
      state.entitiesLoaded = false;
      state.searchTerm = '';
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.uploadedByMe = false;
      state.selectedDocumentCount = 0;
      state.unselectedDocumentCount = 0;
    },
    setDocHubSort(state, action: PayloadAction<DocHubSortType>) {
      state.docHubSort = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    setRenameData(state, action: PayloadAction<RenameData>) {
      state.renameData = action.payload;
    },
    updateFolder(state, action: PayloadAction<UpdateFolderRequest>) {
      const { type, folderId } = action.payload;
      if (type === 'rename') {
        state.entities = state.entities.map((entity) => {
          if (isFolder(entity)) {
            return {
              ...entity,
              deleting: entity.id === folderId ? true : entity.deleting,
            };
          }

          return entity;
        });
      }
    },
    folderUpdated(state, action: PayloadAction<UpdatedFolder>) {
      const {
        id, gilroyToggle, name, error, type,
      } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (isFolder(entity)) {
          return {
            ...entity,
            name: id === entity.id && name?.length && type === 'rename' ? name : entity.name,
            gilroyToggle: id === entity.id && type === 'gilroyToggle' ? gilroyToggle ?? 0 : entity.gilroyToggle,
            deleting: entity.id === id ? false : entity.deleting,
          };
        }

        return entity;
      });
      if (error?.length) {
        state.error = true;
      } else {
        state.renameId = 0;
      }
    },
    setRenameId(state, action: PayloadAction<number>) {
      state.renameId = action.payload;
    },
    toggleSorting(state, action: PayloadAction<string>) {
      if (state.docHubSort.includes(action.payload)) {
        state.docHubSort = action.payload.concat(`:${state.docHubSort.split(':')[1] === 'asc' ? 'desc' : 'asc'}`) as DocHubSortType;
      } else {
        state.docHubSort = action.payload.concat(':desc') as DocHubSortType;
      }
      state.uploadedDocuments = [];
      state.sapSelectedDocOffset = 0;
      state.entities = [];
      state.entitiesLoaded = false;
      state.entitiesEnded = false;
    },
    toggleFoldersPopupSorting(state, action: PayloadAction<string>) {
      if (state.foldersPopup.sortType.includes(action.payload)) {
        state.foldersPopup.sortType = action.payload.concat(`:${state.foldersPopup.sortType.split(':')[1] === 'asc' ? 'desc' : 'asc'}`) as DocHubSortType;
      } else {
        state.foldersPopup.sortType = action.payload.concat(':desc') as DocHubSortType;
      }
      state.foldersPopup.folders = [];
      state.foldersPopup.loaded = false;
      state.foldersPopup.ended = false;
    },
    toggleSort(state, action: PayloadAction<SortType>) {
      if (action.payload === state.sortType) {
        if (state.sort === 'asc') state.sort = 'des';
        else state.sort = 'asc';
      } else {
        state.sortType = action.payload;
        state.sort = 'des';
      }
      state.entities = [];
      state.entitiesLoaded = false;
      state.uploadedDocuments = [];
      state.sapSelectedDocOffset = 0;
      if (state.sortType === 'date') {
        state.sortFlurryEvent = `date_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'name') {
        state.sortFlurryEvent = `name_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'size') {
        state.sortFlurryEvent = `filesize_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'status') {
        state.sortFlurryEvent = `status_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'uploadedBy') {
        state.sortFlurryEvent = `uploadedBy_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      }
    },
    uploadDocument(state, action: PayloadAction<UploadRequest>) {
      const { file } = action.payload;
      state.flurryFlag = false;
      state.uploaded = false;
      state.documentUploadCount = 0;
      if (action.payload) {
        state.uploading = true;
      }
      if (action.payload.fileUrl) {
        state.batchUploading = true;
      }
      const updatedDocuments = state.allUploadDocuments.map(
        (doc) => (doc.file.uid === file?.uid
          ? { ...doc, status: 'inProgress' }
          : doc),
      );

      state.allUploadDocuments = updatedDocuments;
    },
    updateFileName(state, action: PayloadAction<FileWithId>) {
      state.uploadDocuments[0] = action.payload;
    },
    validateFileName(state, action: PayloadAction<ValidateFileNameRequest>) {
      state.validateLoading = true;
    },
    setUploadDocumentStatus(state, action: PayloadAction<UploadDocumentStatus>) {
      const { filenameAllowed } = action.payload;
      state.uploadDocumentStatus = filenameAllowed;
      state.validateLoading = false;
    },
    setUploadDocumentError(state, action: PayloadAction<boolean>) {
      state.uploadingFileError = action.payload;
    },
    documentUploaded(state, action: PayloadAction<DocumentUploaded>) {
      const { file, inSmartDrawer } = action.payload;
      state.uploaded = true;
      state.uploading = false;
      if (!_.isEmpty(action.payload) && state.entitiesLoaded) {
        state.uploadedDocuments = [{
          ...file, showBlip: true, showPopover: true, isSelected: inSmartDrawer, type: 'document',
        },
        ...state.uploadedDocuments];
        state.documentCount += 1;
        state.userDocumentCount += 1;
        if (state.currentFolder?.id) {
          state.documentHubHeader.count += 1;
        }
      }
      if (inSmartDrawer) {
        state.selectedDocumentCount += 1;
        if (!state.isSelectAll) {
          state.selectedDocuments = [...state.selectedDocuments, { id: file.id, type: 'document', count: 1 }];
        }
      } else {
        state.unselectedDocumentCount += 1;
      }
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
      if (!action.payload) {
        state.drawerState = 'file';
        state.batchSize = initialState.batchSize;
        state.allUploadDocuments = [];
        state.uploadDocuments = [];
        state.failedDocuments = [];
        state.batchUploading = false;
      }
    },
    deleteDocument(state, action: PayloadAction<DocumentDeleteRequest>) {
      const { selectedDocuments } = action.payload;
      const id = selectedDocuments?.split(',').map((docId) => parseInt(docId.trim(), 10));
      if (id) {
        if (state.isSelectAll) {
          state.entities = state.entities.map((doc) => ({
            ...doc,
            deleting: !id.includes(doc.id),
          }));
          state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
            ...doc,
            deleting: !id.includes(doc.id),
          }));
        } else {
          state.entities = state.entities.map((doc) => ({
            ...doc,
            deleting: id.includes(doc.id),
          }));

          state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
            ...doc,
            deleting: id.includes(doc.id),
          }));
        }
        state.deleteing = true;
        state.deleteLoading = true;
        state.isSelectAll = false;
        state.selectedDocuments = [];
        state.unSelectedDocuments = [];
        state.searchList = [];
      }
    },
    deleteFolder(state, action: PayloadAction<FolderDeleteRequest>) {
      const { isSelectAll, selectedDocuments, unSelectedDocuments, selectedFolders, unSelectedFolders } = action.payload;
      const selectedIds = [...selectedDocuments.split(',').map(Number), ...selectedFolders.split(',').map(Number)];
      const unSelectedIds = [...unSelectedDocuments.split(',').map(Number), ...unSelectedFolders.split(',').map(Number)];
        state.entities = state.entities.map((doc) => ({
          ...doc,
          deleting: isSelectAll? !unSelectedIds.includes(doc.id) : selectedIds.includes(doc.id),
        }));
        state.deleteing = true;
        state.deleteLoading = true;
        state.isSelectAll = false;
        state.selectedDocuments = [];
        state.unSelectedDocuments = [];
        state.searchList = [];
    },
    documentDeleted(state, action: PayloadAction<DocumentDeletedType>) {
      const {
        deletedDocumentIds, searchCountDelete,
      } = action.payload;

      state.entities = state.entities.map((doc) => ({
        ...doc,
        deleting: false,
      })).filter((doc) => !deletedDocumentIds.includes(doc.id));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        deleting: false,
      })).filter((doc) => !deletedDocumentIds.includes(doc.id));
      state.documentCount = searchCountDelete ?? (state.documentCount - deletedDocumentIds.length);
      state.deleteing = false;
      state.deleteLoading = false;
      state.userDocumentCount -= deletedDocumentIds.length;
      state.documentHubHeader.count -= deletedDocumentIds.length;
    },
    foldersDeleted(state, action: PayloadAction<FolderDeletedType>) {
      const {
        selectedDocuments, unSelectedDocuments, selectedFolders, unSelectedFolders, isSelectAll,
      } = action.payload;
      const selectedIds = [...selectedDocuments.split(',').map(Number), ...selectedFolders.split(',').map(Number)];
      const unSelectedIds = [...unSelectedDocuments.split(',').map(Number), ...unSelectedFolders.split(',').map(Number)];

      state.entities = state.entities.map((doc) => ({
        ...doc,
        deleting: false,
      })).filter((doc) => isSelectAll? !unSelectedIds.includes(doc.id) : selectedIds.includes(doc.id));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        deleting: false,
      })).filter((doc) =>isSelectAll? !unSelectedIds.includes(doc.id) : selectedIds.includes(doc.id));
      state.itemCount = isSelectAll? unSelectedIds.length : state.itemCount - selectedIds.length;
      state.documentHubHeader.count = isSelectAll? unSelectedIds.length :state.documentHubHeader.count - selectedIds.length;
      state.deleteing = false;
      state.deleteLoading = false;
    },
    documentDeleteFail(state, action: PayloadAction<number[]>) {
      state.deleteing = false;
      state.deleteLoading = false;
      const id = action.payload;
      if (id) {
        state.entities = state.entities.map((doc) => ({
          ...doc,
          deleting: false,
        }));

        state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
          ...doc,
          deleting: false,
        }));
      }
    },
    deleteFail(state){
      state.deleteing = false;
      state.deleteLoading = false;
        state.entities = state.entities.map((doc) => ({
          ...doc,
          deleting: false,
        }));

        state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
          ...doc,
          deleting: false,
        }));
    },
    downloadDocument(state, action: PayloadAction<string>) {
      const link = action.payload;
      if (link) {
        state.entities = state.entities.map((doc) => {
          if (isDocument(doc)) {
            return {
              ...doc,
              downloading: doc.fileUrl === link,
            };
          }
          return doc;
        });
        state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
          ...doc,
          downloading: doc.fileUrl === link,
        }));
        state.downloading = true;
        state.downloadUrl = link;
      }
    },
    getPreviewDocument(state, action: PayloadAction<DocumentPreviewRequest>) {
    },
    clearPreviewDocument(state) {
      state.fileViewUrl = '';
    },
    documentDownloaded(state) {
      state.entities = state.entities.map((doc) => ({
        ...doc,
        downloading: false,
      }));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        downloading: false,
      }));
      state.downloading = false;
      state.downloadUrl = '';
      state.fileDownloadURL = '';
    },
    renameDocument(state, action: PayloadAction<RenameDocumentRequest>) {
      const { id } = action.payload;
      state.entities = state.entities.map((doc) => ({
        ...doc,
        deleting: doc.id === id,
      }));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        deleting: doc.id === id,
      }));
    },
    documentRenamed(state, action: PayloadAction<Document>) {
      const { id } = action.payload;
      if (id) {
        state.entities = state.entities
          .map((doc) => (doc.id === id ? {
            ...action.payload, showPopover: true, deleting: false, type: 'document',
          } : doc));
        state.uploadedDocuments = state.uploadedDocuments
          .map((doc) => (doc.id === id ? {
            ...action.payload, showPopover: true, deleting: false, type: 'document',
          } : doc));
      }
    },
    getDocumentDownloadURL(state, action: PayloadAction<GetDocumentDownloadRequest>) {
      state.downloadLoadingIds = [...state.downloadLoadingIds, action.payload.id];
    },
    setDocumentDownloadURL(state, action: PayloadAction<SetDownloadURL>) {
      const { fileURL, documentId } = action.payload;
      state.fileDownloadURL = fileURL;
      state.downloadLoadingIds = state.downloadLoadingIds.filter((docId) => docId !== documentId);
    },
    setDocumentPreviewURL(state, action: PayloadAction<SetDownloadURL>) {
      const { fileViewUrl } = action.payload;
      state.fileViewUrl = fileViewUrl;
    },
    setDrawerState(state, action: PayloadAction<string>) {
      state.drawerState = action.payload;
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.entities = [];
      state.entitiesLoaded = false;
      state.uploadedDocuments = [];
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.uploadedByMe = false;
      state.selectedDocumentCount = 0;
      state.unselectedDocumentCount = 0;
    },
    setFoldersPopupSearchText(state, action: PayloadAction<string>) {
      state.foldersPopup.searchTerm = action.payload;
      state.foldersPopup.folders = [];
      state.foldersPopup.loaded = false;
    },
    setUploadDocument(state, action: PayloadAction<FileWithId>) {
      state.uploadDocuments.push(action.payload);
      if (state.uploadDocuments.length) {
        state.batchUploading = true;
      }
    },
    setNextUploadDocument(state, action: PayloadAction<FileWithId>) {
      state.nextUploadDocuments.push(action.payload);
    },
    setAllUploadDocument(state, action: PayloadAction<AllUploadDocument>) {
      state.allUploadDocuments.push(action.payload);
    },
    resetNextUploadDocuments(state) {
      state.nextUploadDocuments = [];
    },
    removeUploadDocument(state) {
      const file = state.uploadDocuments?.[0];
      state.uploadDocuments = state.uploadDocuments?.slice(1);
      state.allUploadDocuments?.filter((obj) => obj.file.uid !== file?.uid);
      if (!state?.uploadDocuments?.length) {
        state.batchUploading = false;
      }
    },
    removeCancelledUploadDocument(state, action:PayloadAction<string>) {
      state.uploadDocuments?.filter((obj) => obj.uid !== action.payload);
      state.allUploadDocuments?.filter((obj) => obj.file.uid !== action.payload);
      if (!state?.uploadDocuments?.length) {
        state.batchUploading = false;
      }
    },
    chnageStatusUploadDocument(state, action:PayloadAction<AllUploadDocument>) {
      const file = action.payload;
      state.uploadDocuments = state.uploadDocuments.filter((d) => d !== file.file);
      state.allUploadDocuments = state.allUploadDocuments.map((d) => ({
        ...d,
        status: d.file === file.file ? 'failed' : d.status,
      }));
      state.failedDocuments.push({
        name: file.file.name,
        size: getFileSize(file.file.size),
        failReason: 'Upload cancelled',
        type: '',
        isLink: false,
        uid: file.file.uid,
      });
    },
    modifyUploadDocument(state, action: PayloadAction<FileWithId>) {
      if (state.uploadDocuments.length === 1) {
        state.uploadDocuments[0] = action.payload;
      }
    },
    setFailDocuments(state, action: PayloadAction<CustomUploadFile>) {
      state.failedDocuments.push(action.payload);
      const updatedDocuments = state.allUploadDocuments.map(
        (doc) => (doc.file.uid === action.payload.uid
          ? { ...doc, status: 'failed', failReason: action.payload.failReason }
          : doc),
      );
      state.allUploadDocuments = updatedDocuments;
    },
    setDocumentUploadCount(state, action: PayloadAction<number>) {
      state.documentUploadCount = action.payload;
    },
    setSuccessDocuments(state, action: PayloadAction<Document>) {
      const { uid } = action.payload;
      state.successDocuments.push(action.payload);
      const updatedDocuments = state.allUploadDocuments.map(
        (doc) => (doc.file.uid === uid
          ? { ...doc, status: 'success' }
          : doc),
      );
      state.allUploadDocuments = updatedDocuments;
      if (state.selectedFolderId) {
        state.documentHubHeader.count += 1;
      }
      if (state.foldersPopup.selectedFolder?.id) {
        const id = state.foldersPopup.selectedFolder?.id;
        state.entities.map((entity) => {
          if (isFolder(entity)) {
            return {
              ...entity,
              totalItems: id === entity.id ? entity.totalItems + 1 : entity.totalItems,
            };
          }

          return entity;
        });
      }
    },
    resetSuccessDocuments(state) {
      state.successDocuments = [];
    },
    setBatchSize(state, action: PayloadAction<number>) {
      state.batchSize = action.payload;
    },
    setBatchUploading(state, action: PayloadAction<boolean>) {
      state.batchUploading = action.payload;
    },
    setUploadLink(state, action: PayloadAction<string>) {
      state.uploadLink = action.payload;
    },
    setCancelPopup(state, action: PayloadAction<CancelPopupType>) {
      state.cancelPopup = action.payload;
    },
    toggleDocumentPopover(state, action: PayloadAction<number>) {
      state.entities = state.entities.map((doc) => ({
        ...doc,
        showPopover: doc.id === action.payload
          ? !doc.showPopover : true,
      }));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        showPopover: doc.id === action.payload
          ? !doc.showPopover : true,
      }));
    },
    setCollabPopupFolderId(state, action: PayloadAction<number>) {
      state.collaboratorsPopup.folderId = action.payload;
    },
    cancelUpload(state) {
      state.uploadDocuments = [];
      state.uploadCancelled = true;
    },
    resetBatch(state) {
      state.batchUploading = false;
      state.batchSize = 0;
      state.uploadDocuments = [];
      state.successDocuments = [];
      state.failedDocuments = [];
      state.uploadLink = '';
      state.fileTypeFlurryEvent = [];
      state.flurryFlag = true;
    },
    setSearchCount(state, action: PayloadAction<number>) {
      state.searchCountDelete = action.payload;
    },
    resetUploadCancel(state) {
      state.uploadCancelled = false;
    },
    pushFileTypeFlurry(state, action: PayloadAction<string>) {
      state.fileTypeFlurryEvent.push(action.payload);
    },
    setPreviewDrawerOpen(state, action: PayloadAction<boolean>) {
      state.previewDrawerOpen = action.payload;
    },
    setCreateFolderPopupData(state, action: PayloadAction<CreateFolderPopup>) {
      state.createFolderPopup = action.payload;
    },
    setCreateFolderPopupVisible(state, action: PayloadAction<boolean>) {
      state.createFolderPopup.visible = action.payload;
    },
    setCreateFolderName(state, action: PayloadAction<string>) {
      state.createFolderPopup.folderName = action.payload;
    },
    setCreateFolderPopupGilroyToggle(state, action: PayloadAction<boolean>) {
      state.createFolderPopup.gilroyToggle = action.payload;
    },
    setCollabPopupType(state, action:PayloadAction<CollabPopupViewType>) {
      state.collaboratorsPopup.currentView = action.payload;
    },
    toggleFoldersPopup(state, action: PayloadAction<boolean>) {
      state.foldersPopup.visible = action.payload;
      if (!action.payload) {
        state.foldersPopup = {
          ...initialState.foldersPopup, selectedFolder: state.foldersPopup.selectedFolder,
        };
        if (!state.foldersPopup.selectedFolder) {
          state.drawerOpen = false;
        }
      } else {
        state.foldersPopup.selectedFolder = null;
      }
    },
    setFoldersPopupType(state, action: PayloadAction<'move' | 'copy' | 'upload'>) {
      state.foldersPopup.type = action.payload;
    },
    pushFoldersPopupSelectedDocument(state, action: PayloadAction<number>) {
      state.foldersPopup.selectedDocumentIds = [...state.foldersPopup.selectedDocumentIds, action.payload];
    },
    toggleCollabPopup(state, action: PayloadAction<boolean>) {
      state.collaboratorsPopup.visible = action.payload;
      if (!action.payload) {
        state.collaboratorsPopup = initialState.collaboratorsPopup;
      }
    },
    setCollabPopupUpdating(state, action: PayloadAction<boolean>) {
      state.collaboratorsPopup.updating = action.payload;
    },
    setCreateFolderPopupError(state, action: PayloadAction<string>) {
      state.createFolderPopup.error = action.payload;
    },
    setSelectDocument(state, action: PayloadAction<number>) {
      const documentIndex = state.entities.findIndex(
        (item) => item.id === action.payload,
      );
      if (documentIndex !== -1) {
        state.entities[documentIndex].isSelected = !state.entities[documentIndex].isSelected;
      } else {
        const index = state.uploadedDocuments.findIndex(
          (item) => item.id === action.payload,
        );
        state.uploadedDocuments[index].isSelected = !state.uploadedDocuments[index].isSelected;
      }
    },
    setSelectAllDocuments(state, action: PayloadAction<boolean>) {
      state.entities = state.entities.map((doc) => {
          return {
            ...doc,
            isSelected: doc.deleteAllowed ? action.payload : false,
          };
      });
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: doc.deleteAllowed ? action.payload : false,
      }));
    },
    setSelectAllSapDocuments(state, action: PayloadAction<boolean>) {
      state.entities = state.entities?.map((doc) => ({
        ...doc,
        isSelected: action.payload,
      }));
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: action.payload,
      }));
    },
    setIsSelectAll(state, action: PayloadAction<boolean>) {
      state.isSelectAll = action.payload;
    },
    setIsSearchSelectAll(state, action: PayloadAction<boolean>) {
      state.isSearchSelectAll = action.payload;
    },
    setSelectedDocuments(state, action: PayloadAction<SelectedDocument[]>) {
      state.selectedDocuments = action.payload;
    },
    setUnselectedDocuments(state, action: PayloadAction<SelectedDocument[]>) {
      state.unSelectedDocuments = action.payload;
    },
    setSearchList(state, action: PayloadAction<SearchList[]>) {
      state.searchList = action.payload;
    },
    setUploadedByMe(state, action: PayloadAction<boolean>) {
      state.uploadedByMe = action.payload;
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.selectedDocumentCount = 0;
      state.unselectedDocumentCount = 0;
    },
    setIsItemsSelectAll(state, action: PayloadAction<boolean>){
      state.isSelectAll = !action.payload;
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.entities = state.entities?.map((doc) => ({
        ...doc,
        isSelected: doc.deleteAllowed? !action.payload: false,
      }));
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: !action.payload,
      }));
    },

    setSelectedDocumentsCount(state, action: PayloadAction<number>) {
      state.selectedDocumentCount = action.payload;
    },

    setUnselectedDocumentsCount(state, action: PayloadAction<number>) {
      if(action.payload > -1){
        state.unselectedDocumentCount = action.payload;
      }
      else{
        state.unselectedDocumentCount = 0;
      }
    },

    setSapSelectedDocOffset(state, action: PayloadAction<number>) {
      state.sapSelectedDocOffset = action.payload;
    },

    setShowSelected(state, action: PayloadAction<boolean>) {
      state.showSelected = action.payload;
      state.entities = [];
      state.entitiesLoaded = false;
      state.uploadedDocuments = [];
    },

    setSapDocuments(state, action: PayloadAction<DocHubEntity[]>) {
      state.entities = action.payload;
      if (!action.payload?.length) {
        state.documentCount = 0;
      } else {
        state.documentCount -= 1;
        state.unselectedDocumentCount = 0;
      }
    },

    addRemoveDocInWholeSearchList(state, action: PayloadAction<AddRemoveDocInSearchList>) {
      const { isAdd, id, type, count } = action.payload;
      if (isAdd) {
        state.searchList = state.searchList?.map((item) => {
          if (!item.isSelectAll) {
            return item;
          }
          return {
            ...item,
            ids: item.ids?.filter((itemId) => itemId.id !== id),
          };
        });
      } else {
        state.searchList = state.searchList?.map((item) => {
          if (!item.isSelectAll) {
            return item;
          }
          return {
            ...item,
            ids: item.ids?.some(
              (itemId) => itemId.id === id,
            ) ? item?.ids : [...item.ids, { id, type, count }],
          };
        });
      }
    },

    setSelectedFolderId(state, action: PayloadAction<number>) {
      state.selectedFolderId = action.payload;
    },

    resetSelectedDocuments(state, action: PayloadAction<boolean>) {
      const isSap = action.payload;
      state.isSelectAll = false;
      state.isSearchSelectAll = false;
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.searchList = [];
      state.selectedDocumentCount = 0;
      state.showSelected = false;
      state.unselectedDocumentCount = state.isAdmin || isSap
        ? state.documentCount : state.userDocumentCount;
      state.entities = state.entities?.map((doc) => ({
        ...doc,
        isSelected: false,
      }));
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: false,
      }));
    },
    resetSelectedPeople(state, action: PayloadAction<boolean>) {
      const isSelectAllOperation = action.payload;
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const isSelectAll = isCollaborator ? 'isCollaboratorsSelectAll' : 'isPeopleSelectAll';
      const isSearchSelectAll = isCollaborator ? 'isCollaboratorsSearchSelectAll' : 'isPeopleSearchSelectAll';
      const selected = isCollaborator ? 'selectedCollaborators' : 'selectedPeople';
      const unselected = isCollaborator ? 'unSelectedCollaborators' : 'unSelectedPeople';
      const searchList = isCollaborator ? 'collaboratorsSearchList' : 'peopleSearchList';
      const dataKey = isCollaborator ? 'collaborators' : 'people';

      state.collaboratorsPopup = {
        ...state.collaboratorsPopup,
        [isSelectAll]: isSelectAllOperation,
        [isSearchSelectAll]: false,
        [selected]: [],
        [unselected]: [],
        [searchList]: [],
        [dataKey]: (state.collaboratorsPopup[dataKey] as Executive[])?.map((person: Executive) => ({
          ...person,
          isSelected: isSelectAllOperation,
        })) || [],
        // selectedDocumentCount: 0,
        // unselectedDocumentCount: state.isAdmin ? state.documentCount : state.userDocumentCount,
      };
    },
    setSelectAllPeople(state, action: PayloadAction<SelectAllPeopleRequest>) {
      const { dataKey, isSelectAll } = action.payload;

      state.collaboratorsPopup = {
        ...state.collaboratorsPopup,
        [dataKey]: (state.collaboratorsPopup[dataKey] as Executive[])?.map((person) => ({
          ...person,
          isSelected: isSelectAll,
        })),
      };
    },
    updateCollaboratorsAccess(state ,action: PayloadAction<UpdateCollaboratorsAccess>){
      const {id, type} = action.payload;
      state.collaboratorsPopup.collaborators = state.collaboratorsPopup.collaborators.map(collaborator => {
       if( collaborator.userId === id){
        return {
          ...collaborator,
          editAccess: type === 'edit' ? 1 : 0,
          viewAccess: type === 'view' ? 1 : 0,
          removeAccess: type === 'remove'? 1 : 0,
        }
       }
       else {
        return collaborator;
       }
      })
    },
    setSelectPerson(state, action: PayloadAction<SelectPersonRequest>) {
      const { dataKey, id } = action.payload;
      state.collaboratorsPopup = {
        ...state.collaboratorsPopup,
        [dataKey]: (state.collaboratorsPopup[dataKey] as Executive[])?.map((person) => (
          person.userId === id
            ? {
              ...person,
              isSelected: !person.isSelected,
            } : person)),
      };
    },
    setIsPeopleSearchSelectAll(state, action: PayloadAction<boolean>) {
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const isSearchSelectAll = isCollaborator ? 'isCollaboratorsSearchSelectAll' : 'isPeopleSearchSelectAll';
      state.collaboratorsPopup[isSearchSelectAll] = action.payload;
    },
    setPeopleSearchTerm(state, action: PayloadAction<string>) {
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const searchTerm = isCollaborator ? 'collaboratorsSearchTerm' : 'peopleSearchTerm';
      state.collaboratorsPopup[searchTerm] = action.payload;
    },
    setPeopleSearchSelectionToggle(state, action: PayloadAction<PeopleSearchSelectionToggle>) {
      const { dataKey, ...newSearch } = action.payload;
      const {
        searchTerm, isSelectAll, ids,
      } = newSearch;
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const selectedDataKey = isCollaborator ? 'selectedCollaborators' : 'selectedPeople';
      const searchList = isCollaborator ? 'collaboratorsSearchList' : 'peopleSearchList';

      const index = state.collaboratorsPopup[searchList].findIndex(
        (item) => item.searchTerm === searchTerm,
      );
      if (isSelectAll) {
        const personIds = (state.collaboratorsPopup[dataKey] as Executive[])?.map(
          (person) => person.isSelected && person.userId,
        );
        state.collaboratorsPopup[selectedDataKey] = state.collaboratorsPopup[selectedDataKey]
          .filter(
            (sPerson) => !personIds.includes(sPerson),
          );
      } else {
        const personIds = (state.collaboratorsPopup[dataKey] as Executive[])?.map(
          (person) => person.isSelected && person.userId,
        );
        state.collaboratorsPopup[selectedDataKey] = state.collaboratorsPopup[selectedDataKey]
          .filter(
            (sPerson) => !personIds.includes(sPerson),
          );
      }
      let prevSelectAll = false;
      let prevIds = [];
      if (index === -1) {
        state.collaboratorsPopup[searchList] = [...state.collaboratorsPopup[searchList], newSearch];
      } else {
        prevSelectAll = state.collaboratorsPopup[searchList][index].isSelectAll;
        prevIds = state.collaboratorsPopup[searchList][index].ids;
        const updatedSearchList = { ...state.collaboratorsPopup[searchList][index] };
        updatedSearchList.isSelectAll = isSelectAll;
        updatedSearchList.ids = ids;
        state.collaboratorsPopup[searchList].splice(index, 1);
        state.collaboratorsPopup[searchList] = [...state.collaboratorsPopup[searchList],
          updatedSearchList];
      }
    },

    updatePeopleSearchListWhenIdinOtherObj(
      state,
      action: PayloadAction<SetPeopleSearchListWithIndex>,
    ) {
      const { search, index } = action.payload;
      const {
        ids, isPrevSelected,
      } = search;
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const searchList = isCollaborator ? 'collaboratorsSearchList' : 'peopleSearchList';

      const { isSelectAll } = state.collaboratorsPopup[searchList][index];
      if (!isSelectAll && !isPrevSelected) {
        state.collaboratorsPopup[searchList][index].ids = Array.from(
          new Set([...state.collaboratorsPopup[searchList][index].ids, ...ids]),
        );
      } else if (!isSelectAll && isPrevSelected) {
        state.collaboratorsPopup[searchList][index].ids = state
          .collaboratorsPopup[searchList][index].ids
          .filter(
            (id) => id !== ids[0],
          );
        // state.selectedDocumentCount -= 1;
        // state.unselectedDocumentCount += 1;
      } else if (isSelectAll && !isPrevSelected) {
        state.collaboratorsPopup[searchList][index].ids = state
          .collaboratorsPopup[searchList][index].ids
          .filter(
            (id) => id !== ids[0],
          );
        // state.selectedDocumentCount += 1;
        // state.unselectedDocumentCount -= 1;
      } else if (isSelectAll && isPrevSelected) {
        state.collaboratorsPopup[searchList][index].ids = Array.from(
          new Set([...state.collaboratorsPopup[searchList][index].ids, ...ids]),
        );
      }
    },

    updatePeopleSearchList(state, action: PayloadAction<UpdateSearchListPeople>) {
      const { selectedDataKey, unselectedDataKey, ...newSearch } = action.payload;
      const {
        searchTerm, isSelectAll, ids, isPrevSelected,
      } = newSearch;
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const searchList = isCollaborator ? 'collaboratorsSearchList' : 'peopleSearchList';
      const isSelectAllKey = isCollaborator ? 'isCollaboratorsSelectAll' : 'isPeopleSelectAll';
      const isSearchSelectAll = isCollaborator ? 'isCollaboratorsSearchSelectAll' : 'isPeopleSearchSelectAll';

      const index = state.collaboratorsPopup[searchList].findIndex(
        (item) => item.searchTerm === searchTerm,
      );
      if (index === -1) {
        if (!state.collaboratorsPopup[isSearchSelectAll] && !isSelectAll
          && isPrevSelected && state.collaboratorsPopup[isSelectAllKey]) {
          state.collaboratorsPopup[unselectedDataKey] = [
            ...(state.collaboratorsPopup[unselectedDataKey]),
            ...ids,
          ];
        } else if (!state.collaboratorsPopup[isSearchSelectAll] && !isSelectAll
          && !searchTerm?.length && !isPrevSelected) {
          state.collaboratorsPopup[selectedDataKey] = [...state.collaboratorsPopup[selectedDataKey],
            ...ids];
        } else if (!state.collaboratorsPopup[isSearchSelectAll] && !isSelectAll
          && !searchTerm?.length && isPrevSelected) {
          const obj = newSearch;
          obj.ids = [];
          state.collaboratorsPopup[searchList] = [...state.collaboratorsPopup[searchList], obj];
        } else {
          state.collaboratorsPopup[searchList] = [...state.collaboratorsPopup[searchList],
            newSearch];
        }
      } else if (state.collaboratorsPopup[searchList][index].isSelectAll === isSelectAll) {
        if ((isSelectAll && isPrevSelected) || (!isSelectAll && !isPrevSelected)) {
          const updatedSearchList = { ...state.collaboratorsPopup[searchList][index] };
          updatedSearchList.ids = Array.from(
            new Set([...state.collaboratorsPopup[searchList][index].ids, ...ids]),
          );
          state.collaboratorsPopup[searchList].splice(index, 1);
          state.collaboratorsPopup[searchList] = [...state.collaboratorsPopup[searchList],
            updatedSearchList];
        } else {
          state.collaboratorsPopup[searchList][index].ids = state
            .collaboratorsPopup[searchList][index].ids?.filter(
              (searchId) => searchId !== ids[0],
            );
        }
      } else {
        state.collaboratorsPopup[searchList][index].isSelectAll = isSelectAll;
        state.collaboratorsPopup[searchList][index].ids = ids;
      }
    },
    setSelectedPeople(state, action: PayloadAction<number[]>) {
      const dataKey = state.collaboratorsPopup.currentView === 'collaborators' ? 'selectedCollaborators' : 'selectedPeople';
      state.collaboratorsPopup[dataKey] = action.payload;
    },
    setUnselectedPeople(state, action: PayloadAction<number[]>) {
      const dataKey = state.collaboratorsPopup.currentView === 'collaborators' ? 'unSelectedCollaborators' : 'unSelectedPeople';
      state.collaboratorsPopup[dataKey] = action.payload;
    },
    moveCopyDocuments(state, action: PayloadAction<MoveCopyRequest>){
      state.foldersPopup.moveCopyLoading = true;
    },
    setMoveCopyLoading(state, action: PayloadAction<boolean>){
      state.foldersPopup.moveCopyLoading = action.payload;
    },
    addRemoveDocInWholePeopleSearchList(
      state,
      action: PayloadAction<AddRemoveDocInPeopleSearchList>,
    ) {
      const { isAdd, id } = action.payload;
      const isCollaborator = state.collaboratorsPopup.currentView === 'collaborators';
      const searchList = isCollaborator ? 'collaboratorsSearchList' : 'peopleSearchList';

      if (isAdd) {
        state.collaboratorsPopup[searchList] = state.collaboratorsPopup[searchList]?.map((item) => {
          if (!item.isSelectAll) {
            return item;
          }
          return {
            ...item,
            ids: item.ids?.filter((itemId) => itemId !== id),
          };
        });
      } else {
        state.collaboratorsPopup[searchList] = state.collaboratorsPopup[searchList]?.map((item) => {
          if (!item.isSelectAll) {
            return item;
          }
          return {
            ...item,
            ids: item.ids?.some(
              (itemId) => itemId === id,
            ) ? item?.ids : [...item.ids, id],
          };
        });
      }
    },
    reset: (state) => ({
      ...initialState,
      uploaded: state.uploaded,
      uploading: state.uploading,
      fromGlobalSearch: state.fromGlobalSearch,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = documentHubSlice;
